import React from 'react';
import Layout from '../components/layout';
import DataWrapChart from '../components/datawrap';
import { Grid, Embed,Container} from 'theme-ui';
import ButtonToggle from "../components/buttontoggle"

const MarijuanaPage = () => {

  
    return (
      <Layout>
        <div className="grid-container">
          <div class="notification">

  
              <section className="usa-graphic-list usa-section">
                <div className="grid-container desktop:margin-right-6 desktop:margin-left-6 "  >
                  <div class="" >
                    <h1 class="font-heading-3xl padding-bottom-1 margin-bottom-1 " >10 Years of Wisconsin Possession of Marijuana Convictions</h1>
                  </div>
                  <div className="text-base margin-bottom-2">
                <div className="margin-top-1">
                  By <span className="text-bold"> DA John Chisholm and Branden DuPont</span> ·{' '}
                  3-22-2021
                </div>
                </div>
                </div>

                <div className="grid-container desktop:margin-right-6 desktop:margin-left-6 text-left"  >
                <h1 class="margin-x-0 margin-y-2 font-heading-2xl">Overview</h1>
                <p>
                    Today the Milwaukee DA's office is releasing an analysis for <b> 10 years</b> of marijuana possession arrests and convictions in Wisconsin. In this data story you will find
                    information on possession of marijuana in Wisconsin's justice system incuding:
                    </p>
                    <ul class="usa-collection__item">
                    <li>State and Milwaukee Trends</li>
                    <li>Driver's of Local Change</li>
                    <li>Racial Disparities</li>
                     </ul>

                    <p>
                    This analysis is a timely one. Gov. Tony Evers announced in his 2021-23 biennial budget a proposal to regulate and tax marijuana much like Wisconsin regulates and taxes alcohol. 
                    "Wisconsin would join 15 [red and blue] states, including neighboring Michigan and Illinois, in legalizing recreational marijuana." [<a href="#press-release">1</a>] This proposal
                    enjoys strong support across the state. A 2018 Marquette Law School Poll found 60% of Wisconsin residents support full legalization
                    and 85% support medicinal marijuana. [<a href="#poll">2</a>]
                    </p>
                    <p>
                    Since Janurary 2019, the Milwaukee District Attorney's Office supports the transition from criminal enforcement of possession of marijuana
                    to the administrative regulation of marijuana in Wisconsin. Our office had for many years previously commited to only
                    narrowly issue charges for possession of marijuana in cases with the presence of guns or violence. </p>

                    <p>
                    This data story is part of the Milwaukee County DA's office ongoing commitment to release data and analysis on the justice system.
                    And to address and inform systemic problems through a lens of racial equity.
                    </p>

                    <p>This is not an exhaustive analysis and will only highlight key trends. For instance, a full disparity analysis is limited to black/white 
                    comparisions due to the lack of reliable data on ethnicity for arrests.
                     Underlying data is available behind all charts <a href="https://github.com/milwaukeedao/mke-da-dashboard-data/tree/master/marijuana-possession">here </a>
                      for those interested in examining trends further.
                     </p>

                    <div class="desktop:grid-col-13 tablet:grid-col-13">
    <h1 class="margin-x-0 margin-y-2 font-heading-xl">Key Findings</h1>
    <div><hr></hr></div>
  <ul class="usa-collection__item">
    <div class="usa-collection__body">
      <h4 class="usa-collection__heading">
        <a class="usa-footer__primary-link" href="#arrests"> #1 Over 10 Years, Wisconsin Marijuana Possession Arrests Remain Unchanged,
         Milwaukee County Arrests Down by Half But Remain High</a>
      </h4>
      <ul class="usa-collection__meta" aria-label="More information">
      </ul>
    </div>
  </ul>
<hr></hr>

  <ul class="usa-collection__item">
    <div class="usa-collection__body">
      <h4 class="usa-collection__heading">
        <a class="usa-footer__primary-link " href="#convictions"> #2 In 10 Years, Overall Wisconsin Marijuana Possession Convictions Have Decreased, Milwaukee County Convictions Have Dropped by Over 94%</a>
      </h4>
      <ul class="usa-collection__meta" aria-label="More information">
      </ul>
    </div>
  </ul>
<hr></hr>

  <ul class="usa-collection__item">
    <div class="usa-collection__body">
      <h4 class="usa-collection__heading">
        <a class="usa-footer__primary-link " href="#driven-by-charging"> #3 In Milwaukee, Especially Since 2015, the Decrease in Possession of Marijuana Convictions is Driven by Prosecutor Charging and Diversion Decisions</a>
      </h4>
      <ul class="usa-collection__meta" aria-label="More information">
      </ul>
    </div>
  </ul>
<hr></hr>

  <ul class="usa-collection__item">
    <div class="usa-collection__body">
      <h4 class="usa-collection__heading">
        <a class="usa-footer__primary-link " href="#milwaukee-vs-statewide"> #4 When Milwaukee County is Excluded, Wisconsin Criminal Conviction Trends for Possession of Marijuana Remain Unchanged Over 10 Years</a>
      </h4>
      <ul class="usa-collection__meta" aria-label="More information">
      </ul>
    </div>
  </ul>

<hr></hr>

  <ul class="usa-collection__item">
    <div class="usa-collection__body">
      <h4 class="usa-collection__heading">
        <a class="usa-footer__primary-link " href="#marijuana-conviction"> #5 In 2019, Milwaukee and Dane County Have a Marijuana Possession Conviction Rate 7 Times Lower than Average</a>
      </h4>
      <ul class="usa-collection__meta" aria-label="More information">
      </ul>
    </div>
  </ul>

<hr></hr>

  <ul class="usa-collection__item">
    <div class="usa-collection__body">
      <h4 class="usa-collection__heading">
        <a class="usa-footer__primary-link " href="#racial-disparities"> #6 Racial Disparities Persist in Possession of Marijuana Arrests and Convictions</a>
      </h4>
      <ul class="usa-collection__meta" aria-label="More information">
      </ul>
    </div>
  </ul>

<hr></hr>
  <ul class="usa-collection__item">
    <div class="usa-collection__body">
      <h4 class="usa-collection__heading">
        <a class="usa-footer__primary-link " href="#black-convictions"> #7 In 2019, Black Convictions for Possession of Marijuana are Driven by Counties Outside Milwaukee</a>
      </h4>
      <ul class="usa-collection__meta" aria-label="More information">
      </ul>
    </div>
  </ul>

<hr></hr>
</div>


                </div>

  
                <div className="grid-container text-left " >
                  <a name='arrests'></a>
                  <h1 class="font-heading-lg padding-bottom-5 desktop:margin-right-6 desktop:margin-left-6 padding-top-5 text-left" >#1 Over 10 Years, Wisconsin Marijuana Possession Arrests Remain Unchanged, Milwaukee County Arrests Down by Half But Remain High</h1>
                  
                  <div className=" margin-top-2 desktop:margin-right-6 desktop:margin-left-6" >
                  <p> Statewide arrests for possession of marijuana have shown no consistent trend and remain unchanged. Over a ten year period, arrests for possession of
                    marijuana have not decreased below 14,000 yearly arrests.
                    </p>
                    <p>
                    Between 2015 and 2018, statewide arrests trended upward. 2018 saw the <b>highest amount of arrests in 10 years</b> — a 21.4%  increase compared to 2010.
                    While 2019 statewide arrests were lower than a 10 year average of 15,485, they saw virtually no change from a 2010 baseline.
                    </p>
                    </div>

                  <div className=" margin-top-4 desktop:margin-right-6 desktop:margin-left-6" >
                  <DataWrapChart title="Wisconsin Arrests Trends for Possession of Marijuana Remain Unchanged"   src="https://datawrapper.dwcdn.net/kJUfD/11" />
                  </div>

                  <div className=" margin-top-2 desktop:margin-right-6 desktop:margin-left-6" >
                  <p> Unlike statewide arrest trends that remain unchanged over 10 years, possession of marijuana arrests in Milwaukee County steadily decreased by 59.73% from 2010-2019.
                     The decrease in Milwaukee County arrests brings it in proportion to Milwaukee's population. Arrests now represent ~13% of overall state arrests (down from ~33%) with ~16% of Wisconsin's population.
                    </p>
                    <p>
                    Arrests between 2010 and 2015 dropped steeply by -48.92% — a decrease of -2,341 arrests. Since 2015, arrests trends have leveled out and then decreased by -21.15% — a decrease of -517 arrests.
                    </p>
                    </div>

                  <div className=" margin-top-4 desktop:margin-right-6 desktop:margin-left-6" >
                  <DataWrapChart title="Milwaukee County Arrests for Possession of Marijuana Cut in Half Since 2010."  src="https://datawrapper.dwcdn.net/bKTME/8" />
                  </div>
                  <a name="convictions"></a>
                  <h1 class="font-heading-lg padding-bottom-5 desktop:margin-right-6 desktop:margin-left-6 padding-top-5 text-left" >#2 In 10 Years, Overall Wisconsin Marijuana Possession Convictions Have Decreased, Milwaukee County Convictions Have Dropped by Over 94%</h1>
                  
                  <div className=" margin-top-2 desktop:margin-right-6 desktop:margin-left-6" >
                  <p> 
                    Possession of marijuana includes any conviction for possession of marijuana - 961.41(3g)(e) and synthetic cannabanoids - 961.41(3g)(em). There are four conviction categories:
                    <ul class="usa-collection__item">
                    <li>Any Possession of Marijuana Conviction - a marijuana possession conviction and any other charge. Most common charge combinations are drug paraphernalia, resisting arrest, and bail jumping (~44% of cases with an additional non-possession charge)</li>
                    <li>Only Marijuana Possession Convictions - both felony (2nd and subsequent) and misdemeanor convictions.</li>
                    <li>Misdemeanor Only Marijuana Convictions -  misdemeanor convictions.</li>
                    <li>Felony Only Marijuana Convictions - felony convictions (2nd and subsequent).</li>
                     </ul>

                     Statewide criminal convictions for possession of marijuana have decreased since 2010. The categories with the two
                     largest decreases are only marijuana convictions <b>-34.34%</b> and felony only marijuana convictions <b>-43.20%</b>.
                    </p>
                    </div>

                  <div className=" margin-top-4 desktop:margin-right-6 desktop:margin-left-6" >
                  <DataWrapChart   title="Statewide Criminal Convictions for Possession of Marijuana Have Decreased Since 2010."  src="https://datawrapper.dwcdn.net/kBcR9/6" />
                  </div>

                  <div className=" margin-top-2 desktop:margin-right-6 desktop:margin-left-6" >

                  <p> The Milwaukee County District Attorney's Office along with the Milwaukee Community Justice Council joined the National Institute of Corrections Evidence-Based Decision Making Initiative in 2010. A key goal
                    behind this initiave was to become more evidenced-based in our office's decisionmaking. We focused on diverting or declining cases, like possession of marijuana, away from the justice system when appropriate. This practice informed a
                    D.A. policy implemented in 2015 to not prosecute non-violent individuals who possess 28 grams or less of marijuana.        
                    </p>
                    <p>
                    As a result, unlike statewide trends that decreased slightly over 10 years, Milwaukee County, in all marijuana possession conviction categories saw well over 90% decreases from 2010-2019.
                    Felony only marijuana convictions saw the largest decrease of <b>98.7%</b>.
                    </p>
                    </div>

                  <div className=" margin-top-4 " >
                  <DataWrapChart  title="Milwaukee Criminal Convictions for Possession of Marijuana Have Dropped  Since 2010." aria-label="Interactive line chart" id="datawrapper-chart-bz215" src="https://datawrapper.dwcdn.net/bz215/7/" />
                  </div>
                  <a name="driven-by-charging"></a>
                  <h1 class="font-heading-lg padding-bottom-5 desktop:margin-right-6 desktop:margin-left-6 padding-top-5 text-left" >#3 In Milwaukee, Especially Since 2015, the Decrease in Possession of Marijuana Convictions is Driven by Prosecutor Charging and Diversion Decisions</h1>
                  
                  
                  <div className=" margin-top-2 desktop:margin-right-6 desktop:margin-left-6" >

                  <p> 
                    The chart below explores whether the decline in Milwaukee County possession of marijuana convictions is driven by police arrests or prosecutor
                    decisions to decline or divert cases. It provides a yearly ratio of arrests to convictions. If arrests drove the 
                    decline in convictions, we'd expect the ratio to stay the same or decrease. 
                    </p>
                    <p>
                    Instead we see the opposite. Even as arrests decline, the ratio increases dramatically and indicates the Milwaukee DA's office is driving the decrease.
                    As an example, if in 2019 the Milwaukee District Attorney's Office's maintained a 2010 arrest/conviction ratio of 3.72,
                     this would result in ~ 422 more convictions.
                    </p>
                    </div>

                  <div className=" margin-top-4 desktop:margin-right-6 desktop:margin-left-6" >
                  <DataWrapChart title=""  src="https://datawrapper.dwcdn.net/3u7dg/3/" />
                  </div>
    
                  <div className="">
                  <a name="milwaukee-vs-statewide"></a>
                  <h1 class="font-heading-lg padding-bottom-5 desktop:margin-right-6 desktop:margin-left-6 margin-top-5 text-left padding-bottom-5" >#4 When Milwaukee County is Excluded, Wisconsin Criminal Conviction Trends for Possession of Marijuana Remain Unchanged Over 10 Years</h1>
                  
                  <div className=" margin-top-2 desktop:margin-right-6 desktop:margin-left-6" >

          <p> 
              The consistent decline in statewide convictions for possession of marijuana is driven by Milwaukee County.
            </p>
            <p>
              When Milwaukee is excluded from statewide trends, 2019 convictions see small decreases from a 2010 baseline in 3 out of 4 categories. Notably, 2018 conviction trends without
              Milwaukee saw 10 year highs. And felony convictions only decreased ~3% instead of a ~43% decrease.
            </p>
            </div>

                  <Grid
        gap={0}
        columns={[2, '1fr']}
        width={[350]}>

      <Container>
      

      <Embed       sx={{
            height: "400px",
            width: "1fr",
                    }}
                src="https://datawrapper.dwcdn.net/uwepK/3" 
                /> </Container>

      <Container>
      

      <Embed       sx={{
      height: "400px",
      width: "1fr",
              }}
          src="https://datawrapper.dwcdn.net/B8kQj/5/" 
          />
      </Container>
      <Embed       sx={{
                  height: "400px",
                  width: "1fr",
                          }}
                      src="https://datawrapper.dwcdn.net/ZaHtg/2/" 
                      />
      <Embed       sx={{
      height: "400px",
      width: "1fr",
              }}
          src="https://datawrapper.dwcdn.net/KJmd7/3/" 
          />

    </Grid>
    
    <div className=" margin-top-4 desktop:margin-right-6 desktop:margin-left-6" >

  <p> 
    Milwaukee County's statewide share of convictions with any possession of marijuana in 2010 was 25% — well above it's share of Wisconsin's population (~13%). 
    It now only makes up ~2.4% of the state's marijuana possession convictions.
  </p>
  </div>

    <div className=" margin-top-4 desktop:margin-right-6 desktop:margin-left-6 " >
                  <DataWrapChart title=""  src="https://datawrapper.dwcdn.net/No4xN/5" />
                  </div>

                  <div className=" margin-top-4 desktop:margin-right-6 desktop:margin-left-6" >

    <p> 
      In 2019, not accounting for population, Milwaukee County had a smaller portion of any marijuana possession convictions than 18 other counties like Manitowoc and Eau Claire.
    </p>
    </div>

                  <div className=" margin-top-4  desktop:margin-right-6 desktop:margin-left-6" >
                  <DataWrapChart title=""  src="https://datawrapper.dwcdn.net/UiVJQ/7" />
                  </div>

                  <a name="marijuana-conviction"></a>
                  <h1 class="font-heading-lg padding-bottom-5 desktop:margin-right-6 desktop:margin-left-6 padding-top-5 text-left" >#5 In 2019, Milwaukee and Dane County Have a Marijuana Possession Conviction Rate 7 Times Lower than Average</h1>
                  
                  <div className=" margin-top-4 desktop:margin-right-6 desktop:margin-left-6 " >

<p> 
In 2019, Milwaukee narrowly has the lowest marijuana possession conviction rate (conviction per 10k people) when compared to the 16 most populous counties in Wisconsin (population over 100,000). 
Milwaukee fell from the second highest conviction rate, to one of the lowest. It joined Dane County in 2017 which has historically had low conviction rates.
  </p>
  <p>
Other populous Wisconsin counties show no consistent trend (Sheboygan) or have been steadily increasing (Marathon and Eau Claire).
  </p>
  </div>

                  
                  <div className=" margin-top-4 desktop:margin-right-6 desktop:margin-left-6" >
                  <DataWrapChart title=""  src="https://datawrapper.dwcdn.net/wxh8j/7/" />
                  </div>

                  <a name="racial-disparities"></a>
                  <h1 class="font-heading-lg padding-bottom-5 desktop:margin-right-6 desktop:margin-left-6 padding-top-5 text-left" >#6 Racial Disparities Persist in Possession of Marijuana Arrests and Convictions</h1>
                  
                  <div className=" margin-top-2 desktop:margin-right-6 desktop:margin-left-6" >
                  <p> Racial disparities persist in possession of marijuana arrests. A National ACLU report (2010-2018) found
                   statewide Black people are 4.2 times more likely than white people to be arrested for marijuana possession.
                   Milwaukee County's arrest disparity is lower than Wisconsin, but Black people are 3.2 times more likely than White people to be arrested.
                   The worst disparities in Wisconsin are in Ozaukee County (34.9 more likely) and Manitowoc (29.9 more likely). [<a href="#aclu">3</a>]
                    </p>
                    <p>
                    Racial disparities also persist in possession of marijuana convictions. Statewide, in 2019, Black people are 4.3 times more likely than white people to be convicted.
                    In 2019, Black people had 14 convictions per 10,000 compared to White people with 3.27 convictions per 10,000.
                    This is a substantial decrease from 2010 where black people were <b>9.5 times more likely</b>. In 2010, Black people had 39.23 convictions per 10,000 
                    compared to White people with 4.1 convictions per 10,000.
                    </p>
                    <p>
                    In 2019, American Indian or Alaskan Native convictions reached 10 year highs. American Indian or Alaskan Native marijuana possession convictions have
                    the largest disparities in the state since 2017.
                    
                    </p>
                    </div>

                  <div className=" margin-top-2 desktop:margin-right-6 desktop:margin-left-6 " >
                    
                  <ButtonToggle
                    buttonText={['Count', 'Rate']}
                    srcs={{
                      left: "https://datawrapper.dwcdn.net/QSqUy/",
                      right: "https://datawrapper.dwcdn.net/Vg9a0/",
                    }}
                  />
                    
                  </div>

                  <div className=" margin-top-2 desktop:margin-right-6 desktop:margin-left-6" >
                  <p> This decline in Black marijuana possession convictions is driven entirely by Milwaukee County. Since 2010, Milwaukee's 
                    Black convictions have dropped by 95%. In 2019, Milwaukee's Black conviction rate is 1.7 per 10,000 — considerably lower than
                    the statewide rate of 14 convictions per 10,000. Despite this large drop overall, absolute disparities between White and Black convictions
                    are larger in 2019 than in 2010.
                    </p>
                    </div>

                  <div className=" margin-top-4 desktop:margin-right-6 desktop:margin-left-6" >


                  <ButtonToggle
                    buttonText={['Count', 'Rate']}
                    srcs={{
                      left: "https://datawrapper.dwcdn.net/N5RIX/",
                      right: "https://datawrapper.dwcdn.net/JnPxZ/",
                    }}
                  />
                  </div>
                  <a name="black-convictions"></a>
                  <h1 class="font-heading-lg padding-bottom-5 desktop:margin-right-6 desktop:margin-left-6 padding-top-5 text-left" >#7 In 2019, Black Convictions for Possession of Marijuana are Driven by Counties Outside Milwaukee</h1>
                  
                  <div className=" margin-top-2 desktop:margin-right-6 desktop:margin-left-6" >
                  <p> There are striking disparities in Black marijuana possession convictions outside Milwaukee County. In 2019, Milwaukee County
                      had 69% of the state's Black population, but only 8% of its marijuana possession convictions. <b> 92%</b> of Black marijuana possession
                      convictions occur outside Milwaukee County, despite the rest of Wisconsin representing only <b>31%</b> of the Black population.

                    </p>
                    </div>

                  <div className=" margin-top-4 desktop:margin-right-6 desktop:margin-left-6" >
                  <DataWrapChart title=""  src="https://datawrapper.dwcdn.net/whcIT/6" />
                  </div>



</div>

<hr className="margin-top-4"></hr>

<ul class="usa-collection__item">
            <ul>1. <a name="press-release" href="https://content.govdelivery.com/accounts/WIGOV/bulletins/2bfca49" >Gov. Evers Proposes Legalizing Marijuana, Investing Portion of Revenue in Equity Initiatives and Rural Schools: February 7, 2021 </a></ul>
            <ul>2. <a name='poll' href="https://law.marquette.edu/poll/wp-content/uploads/2016/07/MLSP36ToplinesRV.pdf">
              Marquette Law School Poll – July 7-10, 2016 </a> </ul>
            <ul>3. <a name="aclu" href="https://www.aclu.org/report/tale-two-countries-racially-targeted-arrests-era-marijuana-reform">
              A Tale of Two Countries Racially Targeted Arrests in the Era of Marijuana Reform: ACLU, 2020</a></ul>
                     </ul>
                </div>
              </section>
            </div>
          </div>



      </Layout>
    );
  };

  export default MarijuanaPage